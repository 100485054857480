var config = {
  // Set the Demo Version
  demo: false,

  //SEO Configurations
  appName: "Naija Quiz",
  metaDescription: "Naija Quiz. is a Web Quiz Application",
  metaKeyWords: "Naija Quiz.,Quiz,Questions,Answers,Online Quiz",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://admin.naijaquiz.com/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  questionTimerSeconds: 15,
  guesstheTimerSeconds: 30,
  levelWinCheckPoint: 30, // Above 30% is required to Clear the Quiz Level
  maxWinningCoins: 4, // This will give user the coins if user will clear the level
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  addCorrectAnswerScore: 2, // This will increase the user score after each correct answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line
  DefaultCountrySelectedInMobile: "in", //Default Country Selected in Mobile Login Screen
  Guessthewordhintcoin: 5,

  //Firebase Configurations

  apiKey: "AIzaSyAfVqK3U4yMqTy6U_uhk34nikHA7bJkmuc",
  authDomain: "naija-quiz-d350a.firebaseapp.com",
  projectId: "naija-quiz-d350a",
  storageBucket: "naija-quiz-d350a.appspot.com",
  messagingSenderId: "776818378968",
  appId: "1:776818378968:web:103416d7d3525746566fad",
  measurementId: "G-XRP4DSXT6L",

  //footer area
  companytext: "Naija Quiz made with key principles of a beautiful, effective, simple to use and better code quality with use of functional based component.",
  addresstext: "Address: Port Harcourt, Port Harcourt, Nigeria",
  phonenumber: "",
  email: "info@naijaquiz.com",
  facebooklink: "https://www.facebook.com/profile.php?id=100087483351750",
  instagramlink: "https://www.instagram.com/ipogancareers/",
  linkedinlink: "https://www.linkedin.com/in/naija-quiz-451b93256/",
  weblink: "https://naijaquiz.com/",
  companyname: "Naija Quiz.",
};

export default config;
